import { ValidatorsService } from './../../../../utils/validators.service';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ProcessoService } from '../../../../services/engenharia/processo/processo.service';
import { CommonModule } from '@angular/common';
import { Processo } from '../../../../model/engenharia/processo';
import { SnackBarService } from '../../../../utils/snack-bar.service';
import { BotaoComponent } from '../../../../componentes/botao/botao.component';
import { MensagemErrorsComponent } from '../../../../componentes/mensagem-errors/mensagem-errors.component';
import { ModalComponent } from '../../../../componentes/modal/modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-processo',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    BotaoComponent,
    MensagemErrorsComponent,],
  templateUrl: './processo.component.html',
  styleUrl: './processo.component.scss'
})
export class ProcessoComponent {



  processos: Processo[] = [];
  atualizacao:boolean = false;
  formularioProcesso!:FormGroup;
  mensagem = "Processo salvo com sucesso"

  constructor(
    private processoService: ProcessoService,
    private formBuild:FormBuilder,
    private snackBarService:SnackBarService,
    private dialog:MatDialog,
    private validatorsService:ValidatorsService
  ) {

  }


  ngOnInit(): void {
    this.listarProcessos();
    this.formularioProcesso = this.formBuild.group({
      id:[],
      descricao:['',[Validators.required,this.validatorsService.semEspaçoEmBranco()]],
    });
  }

  public listarProcessos() {
    this.processoService.consultarProcessos().subscribe({
      next: (tipos) => {
        this.processos = tipos;
      },
    });
  }

  public criarProcesso(){
    if(this.formularioProcesso.valid){
      this.processoService.criarProcesso(this.formularioProcesso.value).subscribe({
        next:(res)=>{
          this.snackBarService.openSnackBar(this.mensagem,"my-snack-bar-sucess")
        },error:(error)=>{
          this.snackBarService.openSnackBar(error.error.mensagem,"my-snack-bar-erro")
        },complete:()=>{
          this.listarProcessos();
          this.formularioProcesso.reset();
        }
      });
    }
  }

  buscarPorId(processo:Processo){
    this.formularioProcesso.patchValue(processo)
  }

  public deletarProcesso(nomeArquivo:string,id:number){
    const dialogRef = this.dialog.open(ModalComponent, {
      data: {nomeArquivo:nomeArquivo,textoConteudo:"Processo"},
      enterAnimationDuration: '500ms',
      exitAnimationDuration: '500ms',
    });
    dialogRef.afterClosed().subscribe({
      next:(res)=>{
        if (res) {
        this.deletar(id);
        };
      }
    });
  }

  private deletar(id:number){
    this.mensagem = `Processo ${id} deletado com sucesso`;
    this.processoService.deletarProcesso(id).subscribe({
      next:(res)=>{
        this.snackBarService.openSnackBar(this.mensagem,"my-snack-bar-sucess")
      },error:(error)=>{
        this.snackBarService.openSnackBar(error.error.mensagem,"my-snack-bar-erro")
      },complete:()=>{
        this.listarProcessos()
      }
    });
  }

  public editarProcesso(){
    this.mensagem = `Processo ${this.formularioProcesso.get('id')!.value} salvo com sucesso`
    if(this.formularioProcesso.valid){
      this.criarProcesso();
    }
  
  }

  verificarEstado(valorDigitado:string){
    if(valorDigitado === ''){
      this.formularioProcesso.reset();
      console.log(this.formularioProcesso.value)
    }
  }


  public limparFormulario(){
    this.formularioProcesso.reset();
  }

  public obterValidacao(nome: string): FormControl {
    const control = this.formularioProcesso.get(nome);
    if (!control) {
      throw new Error('Controle de formulario não encontrado' + nome);
    }
    return control as FormControl;
  }

  public closeModal(){
    this.dialog.closeAll()
  }
}
