<div class="card">
    <div class="card-header">
        <div class="h-100 d-flex justify-content-start align-items-center flex-grow-1">
            <h6 class="m-0">CADASTRO DE PROCESSOS</h6>
        </div>
        <div class="h-100 d-flex justify-content-center align-items-center" (click)="closeModal()">
            <i class="fa-solid fa-xmark x-close"></i>
        </div>
    </div>
    <div class="modal-sessao sessao-box-flutuando">
        <form class="form-group" [formGroup]="formularioProcesso">
            <div class="col-12">
                <label for="text" class="form-label">Descricao:</label>
                <div class="input-group mb-3 d-flex flex-column">
                    <input type="text" formControlName="descricao"
                        (keyup)="verificarEstado(this.formularioProcesso.get('descricao')!.value)"
                        class="form-control form-control-sm">
                    <i class="fa-solid fa-xmark x-formulario" (click)="limparFormulario()"></i>
                    <app-mensagem-errors [control]="obterValidacao('descricao')"></app-mensagem-errors>
                </div>
            </div>
            <div class="row d-flex justify-content-center align-items-center">
               <div class="col-12">
                @if(this.formularioProcesso.get('id')!.value !== null){
                    <app-botao label="Editar" status="btn-salvar" icon="icons8-lápis.png" (click)="editarProcesso()"></app-botao>
                    }@else {
                    <app-botao label="Salvar" status="btn-salvar" icon="icons8-disquete.png" (click)="criarProcesso()"></app-botao>
                    }
               </div>
            </div>
        </form>
        <div class="col-12">
            <div class="mt-5 d-flex justify-content-center">
                <h6>Lista de processos</h6>
            </div>
            <table class="table table-sm table-striped">
                <thead>
                    <tr>
                        <th>Descricao</th>
                        <th class="text-center">Editar</th>
                        <th class="text-center">Excluir</th>
                    </tr>
                </thead>
                <tbody>
                    @for (item of processos; track item.id) {
                    <tr>
                        <td>{{item.descricao}}</td>
                        <td class="text-center" (click)="buscarPorId(item)"><i class="fa-solid fa-pen-to-square"></i>
                        </td>
                        <td class="text-center" (click)="deletarProcesso(item.descricao,item.id)"><i
                                class="fa-solid fa-trash-can"></i>
                        </td>
                    </tr>
                    }
                </tbody>
            </table>
        </div>
    </div>