import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Processo } from '../../../model/engenharia/processo';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProcessoService {

  private url  = environment.url+'/engenharia';

  constructor(
    private http:HttpClient
  ) { 

  }

  public consultarProcessos():Observable<Processo[]>{
    return this.http.get<Processo[]>(`${this.url}/eng-processo-aplicavel`)
  }
  
  public criarProcesso(processo:Processo):Observable<Processo>{
    return this.http.post<Processo>(`${this.url}/eng-processo-aplicavel`,processo)
  }

  public buscarProcessoPorId(id:number):Observable<Processo>{
    return this.http.get<Processo>(`${this.url}/eng-processo-aplicavel/${id}`)
  }
  public deletarProcesso(id:number):Observable<Processo>{
    return this.http.delete<Processo>(`${this.url}/eng-processo-aplicavel/${id}`)
  }



}
